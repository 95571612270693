// Contants
import { ADDONS } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
import GoogleMapAutocomplete from '@/components/ui/GoogleMapAutocomplete'
import GoogleMapLoader from '@/components/ui/GoogleMapLoader'
import GoogleMapMarker from '@/components/ui/GoogleMapMarker'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
// Vuex
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ContactForm',
  components: {
    FormButtons,
    GoogleMapLoader,
    GoogleMapMarker,
    GoogleMapAutocomplete
  },
  mixins: [formMixin, uiMixin, validationMixin],
  props: {
    headerText: {
      type: String,
      default: null
    },
    resultText: {
      type: String,
      default: 'Los datos se almacenaron correctamente.'
    }
  },
  data() {
    return {
      // Form
      formFields: {
        phone1: null,
        place: null
      },
      formFieldsValidations: {
        phone1: {
          required: 'Campo obligatorio'
        },
        place: {
          required: 'Debe seleccionar una de las direcciones sugeridas'
        }
      },
      // Others
      placeMarker: null // used to draw the marker in the map
    }
  },
  computed: {
    ...mapGetters('place', ['placeData'])
  },
  watch: {
    'formFields.place'(value) {
      if (value) {
        // Redibujamos la marca en el mapa
        this.setMarkerInMap(value)
      }
    }
  },
  methods: {
    ...mapActions('place', ['updateConfigs']),
    /**
     * When the user must click on cancel button
     */
    handleCancelButton() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Show possibles errors
     *
     * @param {string} error - error to show
     */
    handleError(error) {
      this.modifyAppAlert({
        text: error,
        type: 'error',
        visible: true
      })
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Datos a almacenar en "contact"
      await this.updateConfigs({
        id: this.placeData.id,
        addOns: [
          {
            id: ADDONS.contact,
            configFields: {
              phone1: this.formFields.phone1,
              place: this.formFields.place
            }
          }
        ]
      })
      // Ocultamos dialog
      this.modifyAppDialog({
        visible: false
      })
      // show info
      this.modifyAppAlert({
        text: this.resultText,
        visible: true
      })
    },
    /**
     * Set marker to show in map
     *
     * @param {Object} place - place, address
     */
    setMarkerInMap(place) {
      this.placeMarker = {
        title: place.locality,
        label: place.locality,
        position: {
          lat: place.latitude,
          lng: place.longitude
        }
      }

      // Posicionamos el mapa en el centro de la búsqueda
      this.$refs.googleMapLoader.setCenterMap({
        lat: place.latitude,
        lng: place.longitude
      })
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      phone1: {
        required
      },
      place: {
        required
      }
    }
  }
}
