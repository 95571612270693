// Google
import { loadGoogleMap } from '@/services/googleMap'
// Mixins
import uiMixin from '@/mixins/uiMixin'

export default {
  name: 'GoogleMapsLoader',
  mixins: [uiMixin],
  props: {
    mapConfig: {
      type: Object,
      default() {
        return {
          clickableIcons: false,
          streetViewControl: false,
          panControlOptions: false,
          gestureHandling: 'cooperative',
          mapTypeControl: false,
          fullscreenControl: false,
          zoom: 16,
          center: { lat: 37.36465, lng: -5.96108 }
        }
      }
    }
  },
  data() {
    return {
      google: null,
      map: null
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Establece/obtiene todos los valores necesarios
     * para el componente inicialmente
     */
    async getEveryNeededData() {
      try {
        // Load Google Maps API
        this.google = await loadGoogleMap()
        // Inicializamos mapa
        this.initializeMap()
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      }
    },
    /**
     * Inicializamos mapa
     */
    initializeMap() {
      // Si ya cargo el elemento HTML
      if (this.$refs.googleMap) {
        this.map = new this.google.maps.Map(this.$refs.googleMap, this.mapConfig)
      }
    },
    /**
     * Set center map in a point
     *
     * @param {Object} position - object with attribute "lat" & "lng"
     */
    setCenterMap(position) {
      if (position && position.lat && position.lng && this.map) {
        this.map.setCenter(position)
      }
    }
  }
}
