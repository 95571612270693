// Vue
import Vue from 'vue'
// Constants
import { TRIAL_DAYS } from '@/constants'
// Components
import CardContainer from '@/components/ui/CardContainer'
import PlansSendEmail from '@/components/elements/plans/PlansSendEmail'
import PlansTrialVersion from '@/components/elements/plans/PlansTrialVersion'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
import VuetifyToolBar from '@/components/ui/VuetifyToolBar'
// Vuex
import { mapGetters, mapState } from 'vuex'
// Mixins
import uiMixin from '@/mixins/uiMixin'
// Filters
import { formatPrice, sanitizeHtmlContent } from '@/filters'
// Services
import { getAddOnPackageById } from '@/services/addons'
// Utils
import { get, isNil } from 'lodash'
// Ligthbox
import VueSilentbox from 'vue-silentbox'
Vue.use(VueSilentbox)

export default {
  name: 'PlansDetail',
  components: {
    CardContainer,
    VuetifyContentLoading,
    VuetifyToolBar
  },
  filters: { formatPrice },
  mixins: [uiMixin],
  data() {
    return {
      // Params
      id: get(this.$route, 'params.id', null),
      // Others
      isIntersecting: false,
      planData: null,
      processingRequest: true
    }
  },
  computed: {
    ...mapState('app', ['extraSmallDevice']),
    ...mapGetters('company', ['trialPlan']),
    ...mapGetters('place', ['subscribedPackages']),
    /**
     * Opciones del botón de acción
     *
     * @return {object}
     */
    actionButtonOptions() {
      // Tenemos posibilidad de TRIAL
      const hasTrial = isNil(this.trialPlan) && this.subscribedPackages.length === 0
      // El plan puede ser adquirido por proceso "checkout"
      const hasCheckout = !this.isAdvancePlan
      // Ya tenemos una suscripción sobre dicho plan
      const hasSubscription = Boolean(
        this.subscribedPackages.find((suscribed) => {
          return suscribed.packageId === this.id
        })
      )
      // Estamos usando el TRIAL actualmente
      const inTrial = !isNil(this.trialPlan) && this.trialPlan.daysLeft > -1

      // Etiqueta del botón por defecto
      let buttonLabel = 'Contratar Plan'

      if (!hasCheckout) {
        buttonLabel = 'Contactar con ventas'
      } else if (hasTrial) {
        buttonLabel = `Prueba ${TRIAL_DAYS} días ¡gratis!`
      } else if (!inTrial && hasSubscription) {
        buttonLabel = 'Actualizar plan'
      }

      return {
        label: buttonLabel,
        color: this.isAdvancePlan ? 'primary' : 'secondary'
      }
    },
    /**
     * Se trata del plan "advance"
     *
     * @return {boolean}
     */
    isAdvancePlan() {
      return this.planData.id === 'advance'
    },
    /**
     * Obtenemos el array de precios del plan
     *
     * @return {Object}
     */
    planPrices() {
      return get(this.planData, 'prices', [])
    },
    /**
     * Prepara los datos almacenados en "gallery"
     * para el plugin de "silent-box"
     *
     * @return {Array}
     */
    planGallery() {
      const gallery = get(this.planData, 'gallery', [])
      return gallery.map((img) => {
        if (typeof img === 'string') {
          return {
            src: img
          }
        } else if (typeof img === 'object' && img.src) {
          return img
        } else {
          return false
        }
      })
    },
    /**
     * Obtenemos el título de la toolBar
     *
     * @return {String}
     */
    toolBarTitle() {
      if (this.processingRequest) {
        return 'Cargando datos...'
      }

      return !this.planData ? 'Plan desconocido' : `Contratar Plan ${this.planData.name}`
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    /**
     * Evento lanzado cuando los elementos "interseccionados",
     * aparecen o desaparecen del "pointview"
     *
     * @param {*} entries - elementos "interseccionados"
     */
    handleIntersect(entries) {
      this.isIntersecting = !this.extraSmallDevice && !entries[0].isIntersecting
    },
    /**
     * Cuando pulsamos sobre el botón de "Acción"
     */
    handleClickAction() {
      if (this.isAdvancePlan) {
        this.modifyAppDialog({
          title: 'Enviar solicitud',
          contentComponent: PlansSendEmail,
          hideActionButtons: true,
          visible: true
        })
      } else if (isNil(this.trialPlan) && this.subscribedPackages.length === 0) {
        // Modal de trial
        this.modifyAppDialog({
          hideTitle: true,
          contentComponent: PlansTrialVersion,
          contentComponentProps: {
            id: this.id
          },
          hideActionButtons: true,
          visible: true
        })
      } else if (!isNil(this.trialPlan) || this.subscribedPackages.length > 0) {
        // Redirigimos a checkout
        this.routerPushTo({
          path: `/checkout/${this.id}`
        })
      }
    },
    /**
     * Obtenemos todos los datos necesarios para cargar
     * el componente (vista) correctamente
     */
    async getEveryNeededData() {
      try {
        // Obtenemos datos de plan
        await this.setPlanData()
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
        // Redirigimos a plans
        // Lo devolvemos al listado de planes
        this.routerPushTo({
          path: '/plans'
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Cadena "limpiada"
     *
     * @param {string}
     * @return {string}
     */
    sanitizeString(str) {
      return sanitizeHtmlContent(str)
    },
    /**
     * Establecemos los datos del plan
     */
    async setPlanData() {
      const { addOnPackage } = await getAddOnPackageById(this.id)
      this.planData = addOnPackage
      // Lanzamos error
      if (isNil(this.planData) || !this.planData.active) {
        throw new Error('El identificador que intenta recuperar no existe')
      }
    }
  }
}
