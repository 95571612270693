// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Obtenemos los establecimientos de las compañías que administramos
 *
 * @param {String} referredId - Id de compañía (referred)
 */
export async function getManagedPlacesByReferredId(referredId) {
  const result = await doRequest({
    method: 'get',
    url: `v1/referred/places/${referredId}`
  })

  return result.data
}

/**
 * Aplicamos saldo a la compañía que fue referida por otra en su creación
 *
 * @param {String} companyId - Id de compañía
 * @param {String} placeId - Id de establecimiento
 */
export async function applyCommissionToReferredCompany(companyId, placeId) {
  const result = await doRequest({
    method: 'post',
    url: `v1/referred/apply-commission`,
    data: {
      companyId,
      placeId
    }
  })

  return result.data
}
