export default {
  name: 'GoogleMapMarkers',
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      default: null,
      required: true
    }
  },
  data() {
    return {
      markerMap: null
    }
  },
  watch: {
    marker(newVal) {
      this.removeMaker()
      this.drawMarker(newVal)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  destroyed() {
    this.removeMaker()
  },
  methods: {
    /**
     * Obtenemos y seteamos todos los elementos necesarios
     * para la carga inicial del componente
     */
    async getEveryNeededData() {
      try {
        // Siguiente interacción dibujamos la marca
        this.$nextTick(() => {
          this.drawMarker(this.marker)
        })
      } catch (error) {
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      }
    },
    /**
     * Pinta marca en el mapa
     *
     * @param {object} mark
     */
    drawMarker(mark) {
      const { Marker } = this.google.maps

      // eslint-disable-next-line no-new
      this.markerMap = new Marker({
        position: mark.position,
        marker: mark,
        map: this.map,
        title: mark.title,
        label: mark.label
      })
    },
    /**
     * Elimina todas las marcas del mapa
     */
    removeMaker() {
      this.markerMap.setMap(null)
    }
  },
  // eslint-disable-next-line vue/require-render-return
  render() {}
}
