// Request
import { doRequest } from '@/utils/requestApi'

/**
 * Hacemos uso de los métodos (plantillas) ya creadas de sendinblue
 * para enviar dicho correo
 *
 * @param {object} data - Objeto con todos los datos necesarios para el registro
 * @return {object} - respuesta del servidor
 */
export async function sendAnySendinblueEmail(data) {
  const result = await doRequest({
    method: 'post',
    url: 'v1/email',
    data: {
      ...data
    }
  })

  return result.data
}
