import { REQUEST_EMAIL, PACKAGES } from '@/constants'
// Components
import FormButtons from '@/components/ui/FormButtons'
// Vuex
import { mapGetters } from 'vuex'
// Mixins
import uiMixin from '@/mixins/uiMixin'
import formMixin from '@/mixins/formMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
// Services
import { sendAnySendinblueEmail } from '@/services/email'

export default {
  name: 'PlansSendEmail',
  components: {
    FormButtons
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      // Form
      formFields: {
        name: null,
        email: null,
        phone: null,
        companyId: null,
        companyName: null
      },
      formFieldsValidations: {
        name: {
          required: 'Campo obligatorio'
        },
        email: {
          required: 'Campo obligatorio',
          email: 'Formato incorrecto de email'
        },
        phone: {
          required: 'Campo obligatorio'
        },
        companyId: {
          required: 'Campo obligatorio'
        },
        companyname: {}
      },
      // Others
      packages: PACKAGES
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData'])
  },
  mounted() {
    this.getEveryNeededData()
  },
  methods: {
    /**
     * Cuando pulsamos sobre el botón "Cancelar"
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Obtenemos todos los datos necesarios para cargar
     * el componente (vista) correctamente
     */
    getEveryNeededData() {
      // Inicializa datos
      this.setFormFieldsValues()
    },
    /**
     * Set inital values in formulary field to set the validation correctly
     */
    setFormFieldsValues() {
      this.formFields.name = this.userData.name
      this.formFields.email = this.userData.email
      this.formFields.companyId = this.placeData.id
      this.formFields.companyName = this.placeData.name
    },
    /**
     * Is triggering after the form is correctly
     * validated by "Vuelidate"
     */
    async afterSubmit() {
      // Enviamos email
      await sendAnySendinblueEmail({
        method: 'advancePlanRequest',
        email: REQUEST_EMAIL,
        name: 'Sistema automatizado',
        customParams: this.formFields
      })
      // Mostramos mensaje
      this.modifyAppAlert({
        text: 'Su solicitud fue enviada correctamente, nos pondremos en contacto contigo \
          en el mejor tiempo posible.',
        visible: true
      })
      // Cerramos modal
      this.hideDialog()
    }
  },
  // Validations with Vuelidate
  validations: {
    formFields: {
      name: { required },
      email: { required, email },
      phone: { required },
      companyId: { required },
      companyName: {}
    }
  }
}
