// Constants
import { ADDONS, ADDON_PAYMENT, PACKAGES, TRIAL_DAYS } from '@/constants'
// Components
import ContactForm from '@/components/elements/contact/ContactForm'
import FormButtons from '@/components/ui/FormButtons'
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import addonsMixin from '@/mixins/addonsMixin'
import dataLayerMixin from '@/mixins/dataLayerMixin'
import intercomMixin from '@/mixins/intercomMixin'
import uiMixin from '@/mixins/uiMixin'
// Services
import { saveCustomer } from '@/services/stripe'
import { getAddOnPackageById } from '@/services/addons'
import { applyCommissionToReferredCompany } from '@/services/reffered'
// Vuex
import { mapActions, mapGetters } from 'vuex'
// Utils
import { get, isNil } from 'lodash'

export default {
  name: 'PlansTrialVersion',
  components: { FormButtons, VuetifyContentLoading },
  mixins: [addonsMixin, dataLayerMixin, intercomMixin, uiMixin],
  props: {
    // ID del plan a activar
    id: {
      required: true,
      type: String,
      default: null
    }
  },
  data() {
    return {
      formProcessing: false,
      packages: PACKAGES,
      planData: null,
      processingRequest: true,
      trialDays: TRIAL_DAYS
    }
  },
  computed: {
    ...mapGetters('place', ['placeData']),
    ...mapGetters('company', ['companyData']),
    /**
     * Configuraciones (datos) de contacto del establecimiento
     *
     * @return {Object}
     */
    placeContactData() {
      return get(this.placeAddonsSetupByUser, ADDONS.contact, {})
    },
    /**
     * Obtenemos el stripeCustomerId
     *
     * @return {String}
     */
    stripeCustomerId() {
      return get(this.companyAddonsSetupByUser, `${ADDONS.company}.stripeCustomerId`, null)
    },
    /**
     * La compañía, en su creación ¿fue referida?
     *
     * @return {String}
     */
    referredId() {
      return get(this.companyData, 'referredId', null)
    }
  },
  async mounted() {
    await this.getEveryNeededData()
  },
  methods: {
    ...mapActions('place', ['createSubscriptionInPlace', 'getPlace']),
    ...mapActions('company', ['getCompany', 'updateConfigs']),
    /**
     * Pulsamos sobre el botón "Cancelar"
     */
    handleCancelButton() {
      this.hideDialog()
    },
    /**
     * Pulsamos sobre el botón "Aceptar"
     */
    async handleAcceptButton() {
      try {
        // Loading
        this.formProcessing = true

        // 1. Obtenemos datos del customer
        const customer = isNil(this.stripeCustomerId)
          ? await this.createStripeCustomer()
          : {
              id: this.stripeCustomerId
            }

        // 2. Añadimos suscripción al establecimiento

        // Tarifa mensual por defecto
        const monthlyPrice = this.planData.prices.find((plan) => {
          return plan.id === ADDON_PAYMENT.monthly.value
        })

        // Datos a usar en la petición
        const subscriptionData = {
          stripe: {
            stripeCustomerId: customer.id,
            stripePriceId: monthlyPrice.stripePriceId,
            trialPeriod: TRIAL_DAYS
          },
          subscription: {
            packageId: this.planData.id,
            companyId: this.companyData.id,
            placeId: this.placeData.id,
            data: {
              amount: monthlyPrice.amount,
              currency: 'EUR',
              period: monthlyPrice.period,
              priceId: monthlyPrice.stripePriceId
            }
          }
        }

        await this.createSubscriptionInPlace(subscriptionData)

        // Aplicamos saldo (comisión) si la compañía fue referida
        if (!isNil(this.referredId)) {
          await applyCommissionToReferredCompany(this.companyData.id, this.placeData.id)
        }

        // Modificamos variables de DataLayer
        await this.dataLayerSetVariables({ bakarta_plan: `${this.planData.id}_trial` })

        // Modificamos variable de Intercom
        this.intercomUpdate({ bakarta_plan: `${this.planData.id}_trial` })

        // Mostramos mensaje
        this.modifyAppAlert({
          text: `Se ha activado correctamente tu periodo de ${TRIAL_DAYS} días de prueba del plan ${
            this.packages[this.planData.id].label
          }`,
          visible: true
        })

        // Redirigimos a la guía
        this.routerPushTo({
          path: '/plans-guide'
        })
      } catch (error) {
        // Mensaje KO
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.formProcessing = false
      }
    },
    /**
     * Hide dialog
     */
    hideDialog() {
      this.modifyAppDialog({
        visible: false
      })
    },
    /**
     * Obtenemos todos los datos necesarios para cargar
     * el componente (vista) correctamente
     */
    async getEveryNeededData() {
      try {
        // Mostramos el formulario de contacto
        this.showContactFormDialog()
        // Obtenemos datos de plan
        await this.setPlanData()
      } catch (error) {
        // Show error
        this.modifyAppAlert({
          text: error.message,
          type: 'error',
          visible: true
        })
      } finally {
        this.processingRequest = false
      }
    },
    /**
     * Si el usuario no posee datos de contacto (establecimiento)
     * debemos mostrar el formulario de contacto
     */
    showContactFormDialog() {
      const phone1 = get(this.placeContactData, 'phone1', null)
      const place = get(this.placeContactData, 'place', null)

      if (isNil(phone1) || isNil(place)) {
        // Si no tiene numero o place le pedimos que lo complete
        this.modifyAppDialog({
          title: 'Completa tus datos de contacto',
          contentComponent: ContactForm,
          contentComponentProps: {
            headerText:
              '<b class="primary--text"> Antes de activar la versión de pruebas</b>, indica al menos un <u>teléfono</u> de\
              contacto y una <u>dirección</u> física de tu establecimiento para que tus clientes puedan\
              ubicarte (esta información se hará pública para tus clientes).',
            resultText:
              'Los datos se almacenaron correctamente, ya puedes volver a iniciar el proceso de activación de la prueba.'
          },
          hideActionButtons: true,
          visible: true
        })
      }
    },

    /**
     * Establecemos los datos del plan
     */
    async setPlanData() {
      const { addOnPackage } = await getAddOnPackageById(this.id)
      this.planData = addOnPackage
    },
    /**
     * Creamos el usuario en Stripe y almacenamos "primeros" datos
     * de facturación
     *
     * @return {object} - datos del customer creado
     */
    async createStripeCustomer() {
      // Datos de localización del establecimiento
      const placeDataContact = get(this.placeAddonsSetupByUser, ADDONS.contact, {})
      // 1. Creamos "customer" en Stripe
      const stripeData = {
        customer: {
          description: 'Cliente HORECA',
          address: {
            city: get(placeDataContact, 'place.locality', ''),
            country: get(placeDataContact, 'place.country_short', ''),
            line1: `${get(placeDataContact, 'place.route', '')} ${get(
              placeDataContact,
              'place.street_number',
              ''
            )}`,
            postal_code: get(placeDataContact, 'place.postal_code', ''),
            state: get(placeDataContact, 'place.administrative_area_level_2', '')
          },
          name: this.placeData.name,
          email: this.userData.email
        }
      }

      const { customer } = await saveCustomer(stripeData)

      if (!customer) {
        throw new Error(
          'No se ha podido crear el "customer" correctamente.\
          Comuníquese con su proveedor de servicio.'
        )
      }

      // 2. Creamos las primeros datos de facturación en Firebase
      await this.updateConfigs({
        id: this.companyData.id,
        addOns: [
          {
            id: ADDONS.company,
            configFields: {
              billingAddress: get(this.placeData, 'place', null),
              billingEmail: this.userData.email,
              stripeCustomerId: customer.id
            }
          }
        ]
      })

      // Devolvemos datos del "customer" creado
      return customer
    }
  }
}
